<template>
  <div>
    <head-layout
        :head-btn-options="headBtnOptions"
        head-title="政府部门生态环保检查及处罚情况"
        @head-save="headSave()"
    ></head-layout>
    <el-scrollbar>
      <el-main>
        <el-form :model="dataForm" :rules="rules" ref="formLayout" label-width="200px"
                 class="demo-ruleForm form-container"
                 style="margin-top: 20px; width: 99%;">
          <el-row class="custom-row" :gutter="20">
            <el-col :span="12">
              <el-form-item label="企业名称" prop="companyName">
                <template slot="label">
                  <span>企业名称</span>
                </template>
                <el-input
                    disabled="true"
                    v-model="dataForm.companyName"
                    controls-position="right"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统计月份" prop="statDate">
                <el-date-picker
                    v-model="dataForm.statDate"
                    type="month"
                    format="yyyy-MM"
                    value-format="yyyy-MM"
                    :disabled="type == 'view' || type == 'workflow'"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="custom-row" :gutter="20">
            <el-col :span="12">
              <el-form-item label="填报日期" prop="updateTime">
                <template slot="label">
                  <span>填报日期:</span>
                </template>
                <el-date-picker
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-model="dataForm.updateTime"
                    type="date"
                    disabled="true"
                    placeholder="保存后自动生成"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年累计检查数" prop="yearAccumulateCheckCnt">
                <el-input
                    v-model="yearAccumulateCheckCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :disabled="true"
                    style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.yearAccumulateCheckCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年整改数" prop="yearRectifiedCnt">
                <el-input
                    v-model="yearRectifiedCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :disabled="true"
                    style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.yearRectifiedCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="月整改率" prop="monthRectifiedRate">
                <el-input
                    v-model="monthRectifiedRate"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :disabled="true"
                    style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.monthRectifiedRateS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年整改率" prop="yearRectifiedRate">
                <el-input
                    v-model="yearRectifiedRate"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :disabled="true"
                    style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.yearRectifiedRateS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年处罚起数" prop="yearAccumulatePunishCnt">
                <el-input
                    v-model="yearAccumulatePunishCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :disabled="true"
                    style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.yearAccumulatePunishCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年处罚罚款（万元）" prop="yearAccumulatePunishAmount">
                <el-input
                    v-model="yearAccumulatePunishAmount"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :disabled="true"
                    style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.yearAccumulatePunishAmountS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年累计问题数" prop="yearAccumulateProblemCnt">
                <el-input
                    v-model="yearAccumulateProblemCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :disabled="true"
                    style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.yearAccumulateProblemCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="月检查次数" prop="monthCheckCnt">
                <el-input
                    v-model="dataForm.monthCheckCnt"
                    controls-position="right"
                    maxlength="6"
                    show-word-limit
                    :readonly="disabled"
                    style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.monthCheckCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="月问题总数" prop="monthProblemCnt">
                <el-input
                    v-model="dataForm.monthProblemCnt"
                    controls-position="right"
                    maxlength="6"
                    show-word-limit
                    :readonly="disabled"
                    style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.monthProblemCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="月整改数" prop="monthRectifiedCnt">
                <el-input
                    v-model="dataForm.monthRectifiedCnt"
                    controls-position="right"
                    maxlength="6"
                    show-word-limit
                    :readonly="disabled"
                    style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.monthRectifiedCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="月处罚起数" prop="monthAccumulatePunishCnt">
                <el-input
                    v-model="dataForm.monthAccumulatePunishCnt"
                    controls-position="right"
                    maxlength="6"
                    show-word-limit
                    :readonly="disabled"
                    style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.monthAccumulatePunishCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="月处罚罚款（万元）" prop="monthAccumulatePunishAmount">
                <el-input
                    v-model="dataForm.monthAccumulatePunishAmount"
                    controls-position="right"
                    maxlength="10"
                    show-word-limit
                    :readonly="disabled"
                    style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="dark" :content="dataForm.monthAccumulatePunishAmountS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
    </el-scrollbar>
  </div>
</template>
<script>
let baseUrl = '/api/sinoma-system/dict/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {saveOrUpdate, detail, saveSubmitInterface} from "@/api/report/protectionSafetyFilling";
import {processList as getProcessList} from "@/api/plugin/workflow/process";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {mapGetters} from "vuex";

export default {
  props: {
    rowQuery: {
      type: Object
    }
  },
  name: "protocolEdit",
  mixins: [exForm, draft],
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {
        statType: "ENV"
      },
      disabled: false,
      nodeId: '',
      formLoading: false,
      processTemplateKey: "report_fill_ecological_government",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      rules: {
        statDate: [
          {required: true, message: '请选择填报年月', trigger: ['blur']},
        ],
        monthCheckCnt: [
          {required: true, message: '请输入月检查次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        monthProblemCnt: [
          {required: true, message: '请输入月问题总数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        monthRectifiedCnt: [
          {required: true, message: '请输入月整改数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        monthAccumulatePunishCnt: [
          {required: true, message: '请输入月处罚起数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        monthAccumulatePunishAmount: [
          {required: true, message: '请输入月处罚罚款（万元）', trigger: ['blur']},
          {pattern: /^\d+(\.\d{1,4})?$/, message: '请输入整数或小数，小数点后最多支持四位'}
        ],
      },
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    //年累计检查数
    yearAccumulateCheckCnt() {
      let number = 0;
      if (this.dataForm.yearAccumulateCheckCntS || this.dataForm.monthCheckCnt) {
        number = ((this.dataForm.yearAccumulateCheckCntS != undefined ? Number(this.dataForm.yearAccumulateCheckCntS) : 0) - (this.dataForm.monthCheckCntS != undefined ? Number(this.dataForm.monthCheckCntS) : 0))
            + (this.dataForm.monthCheckCnt != undefined ? Number(this.dataForm.monthCheckCnt) : 0)
      }
      this.dataForm.yearAccumulateCheckCnt = number;
      return number;
    },
    //年累计问题数
    yearAccumulateProblemCnt() {
      let number = 0;
      if (this.dataForm.yearAccumulateProblemCntS || this.dataForm.monthProblemCnt) {
        number = ((this.dataForm.yearAccumulateProblemCntS != undefined ? Number(this.dataForm.yearAccumulateProblemCntS) : 0) - (this.dataForm.monthProblemCntS != undefined ? Number(this.dataForm.monthProblemCntS) : 0))
            + (this.dataForm.monthProblemCnt != undefined ? Number(this.dataForm.monthProblemCnt) : 0)
      }
      this.dataForm.yearAccumulateProblemCnt = number;
      return number;
    },
    //年整改数
    yearRectifiedCnt() {
      let number = 0;
      if (this.dataForm.yearRectifiedCntS || this.dataForm.monthRectifiedCnt) {
        number = ((this.dataForm.yearRectifiedCntS != undefined ? Number(this.dataForm.yearRectifiedCntS) : 0) - (this.dataForm.monthRectifiedCntS != undefined ? Number(this.dataForm.monthRectifiedCntS) : 0))
            + (this.dataForm.monthRectifiedCnt != undefined ? Number(this.dataForm.monthRectifiedCnt) : 0)
      }
      this.dataForm.yearRectifiedCnt = number;
      return number;
    },
    //年处罚起数
    yearAccumulatePunishCnt() {
      let number = 0;
      if (this.dataForm.yearAccumulatePunishCntS || this.dataForm.monthAccumulatePunishCnt) {
        number = ((this.dataForm.yearAccumulatePunishCntS != undefined ? Number(this.dataForm.yearAccumulatePunishCntS) : 0) - (this.dataForm.monthAccumulatePunishCntS != undefined ? Number(this.dataForm.monthAccumulatePunishCntS) : 0))
            + (this.dataForm.monthAccumulatePunishCnt != undefined ? Number(this.dataForm.monthAccumulatePunishCnt) : 0)
      }
      this.dataForm.yearAccumulatePunishCnt = number;
      return number;
    },
    //年处罚罚款（万元）
    yearAccumulatePunishAmount() {
      let number = 0;
      if (this.dataForm.yearAccumulatePunishAmountS || this.dataForm.monthAccumulatePunishAmount) {
        number = ((this.dataForm.yearAccumulatePunishAmountS != undefined ? parseFloat(Number(this.dataForm.yearAccumulatePunishAmountS).toFixed(4)) : 0) - (this.dataForm.monthAccumulatePunishAmountS != undefined ? parseFloat(Number(this.dataForm.monthAccumulatePunishAmountS).toFixed(4)) : 0))
            + (this.dataForm.monthAccumulatePunishAmount != undefined ? parseFloat(Number(this.dataForm.monthAccumulatePunishAmount).toFixed(4)) : 0)
      }
      this.dataForm.yearAccumulatePunishAmount = parseFloat(number.toFixed(4));
      return parseFloat(number.toFixed(4));
    },
    //月整改率
    monthRectifiedRate() {
      let monthRectified = "100%";
      if (this.dataForm.monthProblemCnt) {
        let monthRectifiedRate = (Number(this.dataForm.monthProblemCnt) === 0) ? "100%" : ((Number(this.dataForm.monthRectifiedCnt) / Number(this.dataForm.monthProblemCnt)) * 100).toFixed(0);
        monthRectified = monthRectifiedRate > 100 || monthRectifiedRate == "100%" ? "100%" : (monthRectifiedRate + "%");
      }
      this.dataForm.monthRectifiedRate = monthRectified;
      return monthRectified;
    },
    //年整改率
    yearRectifiedRate() {
      let yearRectified = "100%";
      if (this.yearAccumulateProblemCnt) {
        let yearRectifiedRate = (Number(this.yearAccumulateProblemCnt) === 0) ? "100%" : ((Number(this.yearRectifiedCnt) / Number(this.yearAccumulateProblemCnt)) * 100).toFixed(0);
        yearRectified = yearRectifiedRate > 100 || yearRectifiedRate == "100%" ? "100%" : (yearRectifiedRate + "%");
      }
      this.dataForm.yearRectifiedRate = yearRectified;
      return yearRectified;
    },
    headBtnOptions() {
      let result = [];
      if (this.dataForm.dataState == '3') {
        result.push(
            {
              label: this.$t('cip.cmn.btn.saveBtn'),
              emit: "head-save",
              type: "button",
              icon: "",
              btnOptType: 'save',
            }
        );
      }
      return result;
    },
    formColumn() {
      let that = this
      return [
        {
          label: "企业名称",
          prop: 'companyName',
          labelWidth: 240,
          span: 12,
          showWordLimit: true,
          readonly: true,
        },
        {
          placeholder: false,
          label: "统计月份",
          prop: 'statDate',
          type: "month",
          labelWidth: 240,
          span: 12,
          showWordLimit: true,
          format: 'yyyy-MM',
          valueFormat: 'yyyy-MM',
          disabled: this.disabled,
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "统计月份",
              trigger: "blur"
            }
          ],
        },
        {
          label: "年累计检查数",
          prop: 'yearAccumulateCheckCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          disabled: true,
        },
        {
          label: "年整改数",
          prop: 'yearRectifiedCnt',
          labelWidth: 240,
          span: 12,
          type: 'input',
          disabled: true,
        },
        {
          label: "月整改率",
          prop: 'monthRectifiedRate',
          labelWidth: 240,
          span: 12,
          type: 'input',
          disabled: true,
        },
        {
          label: "年整改率",
          prop: 'yearRectifiedRate',
          labelWidth: 240,
          span: 12,
          type: 'input',
          disabled: true,
        },
        {
          label: "年处罚起数",
          prop: 'yearAccumulatePunishCnt',
          labelWidth: 240,
          span: 12,
          type: 'input',
          disabled: true,
        },
        {
          label: "年处罚罚款（万元）",
          prop: 'yearAccumulatePunishAmount',
          labelWidth: 240,
          span: 12,
          type: 'input',
          disabled: true,
        },
        {
          label: "年累计问题数",
          prop: 'yearAccumulateProblemCnt',
          labelWidth: 240,
          span: 12,
          type: 'input',
          disabled: true,
        },
        {
          placeholder: false,
          label: "月检查次数",
          prop: 'monthCheckCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          min: 0,
          readonly: !['add', 'edit', 'workflow'].includes(this.type),
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "月检查次数",
              trigger: "blur"
            }
          ],
          change: ({value, column}) => {
            that.$nextTick(() => {
              let number = 0;
              if (value || that.dataForm.yearAccumulateCheckCntS) {
                number = (value != undefined ? Number(value) : 0) +
                    ((this.dataForm.yearAccumulateCheckCntS != undefined ? Number(this.dataForm.yearAccumulateCheckCntS) : 0) - (this.dataForm.monthCheckCntS != undefined ? Number(this.dataForm.monthCheckCntS) : 0))
              }
              this.dataForm.yearAccumulateCheckCnt = number;
            })
          },
        },
        {
          placeholder: false,
          label: "月问题总数",
          prop: 'monthProblemCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          min: 0,
          readonly: !['add', 'edit', 'workflow'].includes(this.type),
          rules: [
            {pattern: /^[0-9]\d*$/, message: "月问题总数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "本月安全生产投入",
              trigger: "blur"
            }
          ],
          change: ({value, column}) => {
            that.$nextTick(() => {
              let number = 0;
              if (value || that.dataForm.yearAccumulateProblemCntS) {
                number = (value != undefined ? Number(value) : 0) +
                    ((that.dataForm.yearAccumulateProblemCntS != undefined ? Number(that.dataForm.yearAccumulateProblemCntS) : 0) - (that.dataForm.monthProblemCntS != undefined ? Number(that.dataForm.monthProblemCntS) : 0))
              }
              this.dataForm.yearAccumulateProblemCnt = number;
              let monthRectified = "100%";
              if (value) {
                let monthRectifiedRate = (Number(value) === 0) ? "100%" : ((Number(this.dataForm.monthRectifiedCnt) / Number(value)) * 100).toFixed(0);
                monthRectified = monthRectifiedRate > 100 || monthRectifiedRate == "100%" ? "100%" : (monthRectifiedRate + "%");
              }
              that.dataForm.monthRectifiedRate = monthRectified;
              let yearRectifiedRate = (Number(this.dataForm.yearAccumulateProblemCnt) === 0) ? "100%" : ((Number(this.dataForm.yearRectifiedCnt) / Number(this.dataForm.yearAccumulateProblemCnt)) * 100).toFixed(0);
              that.dataForm.yearRectifiedRate = yearRectifiedRate > 100 || yearRectifiedRate == "100%" ? "100%" : (yearRectifiedRate + "%");
            })
          },
        },
        {
          placeholder: false,
          label: "月整改数",
          prop: 'monthRectifiedCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          min: 0,
          readonly: !['add', 'edit', 'workflow'].includes(this.type),
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "月整改数",
              trigger: "blur"
            }
          ],
          change: ({value, column}) => {
            that.$nextTick(() => {
              let number = 0;
              if (value || that.dataForm.yearAccumulateProblemCntS) {
                number = (value != undefined ? Number(value) : 0) +
                    ((that.dataForm.yearRectifiedCntS != undefined ? Number(that.dataForm.yearRectifiedCntS) : 0) - (that.dataForm.monthRectifiedCntS != undefined ? Number(that.dataForm.monthRectifiedCntS) : 0))
              }
              this.dataForm.yearRectifiedCnt = number;

              let monthRectified = "100%";
              if (this.dataForm.monthProblemCnt) {
                let monthRectifiedRate = (Number(this.dataForm.monthProblemCnt) === 0) ? "100%" : ((Number(value) / Number(this.dataForm.monthProblemCnt)) * 100).toFixed(0);
                monthRectified = monthRectifiedRate > 100 || monthRectifiedRate == "100%" ? "100%" : (monthRectifiedRate + "%");
              }
              that.dataForm.monthRectifiedRate = monthRectified;
              let yearRectifiedRate = (Number(this.dataForm.yearAccumulateProblemCnt) === 0) ? "100%" : ((Number(this.dataForm.yearRectifiedCnt) / Number(this.dataForm.yearAccumulateProblemCnt)) * 100).toFixed(0);
              that.dataForm.yearRectifiedRate = yearRectifiedRate > 100 || yearRectifiedRate == "100%" ? "100%" : (yearRectifiedRate + "%");
            })
          },
        },
        {
          placeholder: false,
          label: "月处罚起数",
          prop: 'monthAccumulatePunishCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          min: 0,
          readonly: !['add', 'edit', 'workflow'].includes(this.type),
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "月处罚起数",
              trigger: "blur"
            }
          ],
          change: ({value, column}) => {
            that.$nextTick(() => {
              let number = 0;
              if (value || that.dataForm.yearAccumulatePunishCntS) {
                number = (value != undefined ? Number(value) : 0) +
                    ((this.dataForm.yearAccumulatePunishCntS != undefined ? Number(this.dataForm.yearAccumulatePunishCntS) : 0) - (this.dataForm.monthAccumulatePunishCntS != undefined ? Number(this.dataForm.monthAccumulatePunishCntS) : 0))
              }
              this.dataForm.yearAccumulatePunishCnt = number;
            })
          },
        },
        {
          placeholder: false,
          label: "月处罚罚款（万元）",
          prop: 'monthAccumulatePunishAmount',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          min: 0,
          readonly: !['add', 'edit', 'workflow'].includes(this.type),
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "月处罚罚款（万元）",
              trigger: "blur"
            }
          ],
          change: ({value, column}) => {
            that.$nextTick(() => {
              let number = 0;
              if (value || that.dataForm.yearAccumulatePunishAmountS) {
                number = (value != undefined ? Number(value) : 0) +
                    ((this.dataForm.yearAccumulatePunishAmountS != undefined ? Number(this.dataForm.yearAccumulatePunishAmountS) : 0) - (this.dataForm.monthAccumulatePunishAmountS != undefined ? Number(this.dataForm.monthAccumulatePunishAmountS) : 0))
              }
              this.dataForm.yearAccumulatePunishAmount = number;
            })
          },
        },
      ]
    },
  },
  created() {
    this.getProcessId();
    let {unitName, unitCode, type, id, nodeId} = this.$route.query;
    this.type = type;
    this.nodeId = nodeId
    this.dataForm.companyName = unitName
    this.dataForm.unitCode = unitCode
    if (['workflow'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  async mounted() {
    let flowData = await detail(this.dataForm);
    if (flowData.data.data.dataState != '3') {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
    this.$forceUpdate();
  },
  methods: {
    initData() {
      detail(this.dataForm).then(res => {
        const item = res.data.data;
        this.dataForm = item;
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.validate((valid) => {
        if (valid) {
          if (this.dataForm.statDate) {
            this.$loading();
            if (this.dataForm.dataState != '4' && this.dataForm.dataState != '3') {
              this.dataForm.dataState = '1';
            }
            this.dataForm.processInsId = null
            this.dataForm.dateSource = "1"
            saveOrUpdate(this.dataForm, this.type).then(res => {
              if (res.data.code === 200 && res.data.data != '数据已存在不能重复填报!') {
                this.dataForm = res.data.data;
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.warning(res.data.data);
              }
            })
                .finally(() => {
                  this.$loading().close();
                })
          } else {
            this.$message.warning("统计日期为空");
          }
        }
      })
    },
    headSubmit(row) {
      if (this.dataForm.statDate) {
        this.$refs.formLayout.validate((valid) => {
          if (valid) {
            this.$loading();
            if (this.dataForm.dataState != '4' && this.dataForm.dataState != '3') {
              this.dataForm.dataState = '1';
            }
            saveOrUpdate(this.dataForm, this.type).then(res => {
              if (res.data.code === 200 && res.data.data != '数据已存在不能重复填报!') {
                this.dataForm = res.data.data;
                this.type = 'edit';
                this.$confirm("确定将提交审核?", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                })
                    .then(() => {
                      this.$loading();
                      let dataForm = this.dataForm
                      this.rpTaskAttributeSubmit(dataForm);
                    }).catch(() => {
                })
                    .finally(() => {
                      this.$loading().close();
                    });
              } else {
                this.$message.warning(res.data.data);
              }
            }).finally(() => {
              this.$loading().close();
            })
          }
        })
      } else {
        this.$message.warning("统计日期为空");
      }
    },
    rpTaskAttributeSubmit(data) {
      this.formProcess.id = data.id;
      this.formProcess.deptId = this.nodeId
      this.handleStartProcess5(true,true).then((res) => {
        this.dataForm.processInsId = res.data.data;
        this.dataForm.dataState = '2';
        saveSubmitInterface(this.dataForm).then((res) => {
          if (res.data.code === 200) {
            this.$emit("closeDialog")
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.initData();
          }
        })
            .catch((err) => {

            })
            .finally(() => {
              this.$loading().close();
            });
      });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
            (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-textarea__inner {
  height: 70vh;
}

.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  border-top: 1px solid rgb(204, 204, 204);
}

::v-deep .el-form-item__content {
  display: flex;
}

.item {
  font-size: 24px;
  margin-left: 6px;
}

.iconLine {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}

.borderLine {
  width: 100%;
  border-top: 1px solid #DCDFE6;
  padding-top: 12px;
}
</style>
